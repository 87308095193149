import { Typography } from "antd"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const { Title } = Typography

const IndexPage = () => (
  <Layout>
    <SEO title="Dashboard" />
    <Title>Work in Progress</Title>
  </Layout>
)

export default IndexPage
